<template>
  <div>
    <portal to="title-page">
      <h1>Crear Transporte</h1>
    </portal>
    <div>
      <v-card class="mx-auto elevation-1">
        <v-card-title>
          <v-icon large left>mdi-plus</v-icon>
          <span class="title font-weight-light">Crear transporte</span>
          <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <!-- Type transport -->
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-if="toursData != null"
                  v-model="tour"
                  :items="toursData"
                  :prepend-icon="mdiPackageVariantClosed"
                  item-text="name"
                  :rules="[v => !!v || 'Elige el tour para el transporte']"
                  label="Tour"
                  required
                  return-object
                  dense
                  v-on:change="validTourTransport()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Numero minimo de pax para ofrecer servicio colectivo"
                  :prepend-icon="mdiAccount"
                  type="number"
                  color="primary"
                  v-model="transportData.minPaxShared"
                  required
                  :rules="[rules.required]"
                  hint="Apartir de cuantos pax es posible dar transporte colectivo"
                  dense
                  :disabled = disabledShared
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Capacidad total de personas en una camioneta"
                  :prepend-icon="mdiAccount"
                  type="number"
                  color="primary"
                  v-model="transportData.maxPaxPrivate"
                  required
                  hint="Sirve para determinar cuantas vanes se usaran para el servicio"
                  :rules="[rules.required]"
                  dense
                  :disabled = disabledPrivate
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  v-model="transportData.transport"
                  :items="selectTransport"
                  :prepend-icon="mdiTrainCar "
                  item-text="text"
                  item-value="value"
                  :rules="[v => !!v || 'Elige el tipo de transporte']"
                  label="¿Incluye transporte?"
                  required
                  dense
                  :disabled = disabledTransport
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :disabled="!validForm "
                  color="primary"
                  class="mr-5"
                  @click="validateForm"
                >Agregar</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mdiTrainCar,
  mdiCurrencyUsd,
  mdiMapMarker,
  mdiPackageVariantClosed,
  mdiAccount,
  mdiClose,
  mdiArrowLeft
} from "@mdi/js";
import Tours from "@/models/Tour.js";
import Transporte from "@/models/Transport.js";
export default {
  name: "TransportCreate",
  data() {
    return {
      /******* icons ***********/
      mdiClose: mdiClose,
      mdiPackageVariantClosed: mdiPackageVariantClosed,
      mdiTrainCar: mdiTrainCar,
      mdiMapMarker: mdiMapMarker,
      mdiAccount: mdiAccount,
      mdiArrowLeft: mdiArrowLeft,
      mdiCurrencyUsd: mdiCurrencyUsd,
      /***********************/
      TransportModel: new Transporte(),
      toursModel: new Tours(),
      /******  data v-model transport  *******/
      transportData: {
        minPaxShared: 0,
        maxPaxPrivate: 0,
        typeTransport: null,
        transport:false
      },
      /*****  data v-model of rute collections   *******/
      tour: {},
      // variable of gets zone and tour (mounted)
      toursData: [],
      /******   change variables   ***********/
      changTour: null,
      rules: {
        required: (v) => (v && v.length > 0) || "Campo requerido",
        min: (v) => v.length >= 4 || "Min 4 caracteres",
      },
      selectTransport: [
        { value: true, text: "Si" },
        { value: false, text: "No" },
      ],
      validForm: true,
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
      caseShared: "shared",
      casePrivate: "private",
      changeTypeTransport: null,
      disabledShared: false,
      disabledPrivate: false,
      disabledTransport: false,
    };
  },
  methods: {
    validateForm() {
      this.validFormInit = false;
      this.transportData.minPaxShared =
        this.transportData.minPaxShared == null
          ? ""
          : this.transportData.minPaxShared;
      this.transportData.maxPaxPrivate =
        this.transportData.maxPaxPrivate == null
          ? ""
          : this.transportData.maxPaxPrivate;
      this.transportData.transport =
        this.transportData.transport == null
          ? false
          : this.transportData.transport;
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        var docDataTransport = {
          minPaxShared: this.transportData.minPaxShared,
          maxPaxPrivate: this.transportData.maxPaxPrivate,
          transport: this.transportData.transport,
        };
        this.TransportModel.add(this.tour.id, docDataTransport)
          .then(() => {
            loading.hide();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `La tarifa del transporte se agrego correctamente `;
            /******  Limpiar variables  *******/
            this.$router.push({ path: "/backend/admin/transport" });
          })
          .catch((error) => {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message =
              "Error no se guardo el cupon, por favor contacte al Administrador si el problema persiste";
          });
      }
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
    validTourTransport(){
      this.changTour = this.tour;
      console.log('data', this.changTour);
      if(this.changTour.transport === 'true'){
        console.log('si incluye transporte');
        this.disabledShared = true,
        this.disabledPrivate = true,
        this.disabledTransport = true,
        this.snackbar.show = true;
        this.snackbar.color = "red";
        this.snackbar.message =
          "Este tour ya incluye transporte";
      }
      else{
        this.disabledShared = false,
        this.disabledPrivate = false,
        this.disabledTransport = false,
        console.log('no incluye transporte');
      }
    },
  },
  computed: {
    disableBtnAdd: function () {
      if (this.validFormInit) {
        return false;
      }
      if (
        !this.transportData.minPaxShared ||
        !this.transportData.maxPaxPrivate ||
        !this.transportData.transport ||
        !this.validForm
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // get data of tours
    this.TransportModel.allTour().onSnapshot((snap) => {
      this.toursData = [];
      snap.forEach((doc) => {
        this.toursData.push({id:doc.id, ...doc.data()});
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
