import BaseModel from './BaseModel';
import {
    DataConverter as TransportDataConverter
} from '@/FirestoreDataConverters/Transport.js';

export default class Transport extends BaseModel {
    constructor(options) {
        super(options);
    }
    allTour() {
        return this.db.collection('tours').where("deleted", "==", 0).withConverter(TransportDataConverter);
    }
    all() {
        return this.db.collection('tours').where('transport', '==', true).withConverter(TransportDataConverter);
    }
    findById(idTour) {
        return this.db.collection('tours').doc(idTour).withConverter(TransportDataConverter);
    }
    add(idTour, obj) { // update record
        return this.db.collection('tours').doc(idTour).withConverter(TransportDataConverter).update(obj);
    }
    delete(idTour) { // delete record of transport
        return this.db.collection('tours').doc(idTour).delete();
    }
    findData(idTour) {
        return this.db.collection('tours').doc(idTour).where('transport', '==', 'true').withConverter(TransportDataConverter);
    }
}