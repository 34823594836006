class Transport {
    constructor(data) {
        // data tour 
        this.name = data.name || '';
        this.title = data.title || '';
        //this.metadescription = data.metadescription || '';
        this.status = data.status || 'inactive';
        this.deleted = data.deleted || 0;
        this.createdDate = data.createdDate;
        // / *************************/
        this.minPaxShared = data.minPaxShared || '';
        this.maxPaxPrivate = data.maxPaxPrivate || ''; // number of person in case shared
        this.transport = data.transport || '';
    }
}
var DataConverter = {
    toFirestore: function(transport) {
        return {
            name: transport.name,
            title: transport.title,
            status: transport.status,
            deleted: transport.delete,
            createdDate: transport.createdDate,
            // transport
            minPaxShared: transport.minPaxShared,
            maxPaxPrivate: transport.maxPaxPrivate,
            transport: transport.transport,
        }
    },
    fromFirestore: function(snapshot, options) {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return new Transport(data)
    }
}
export {
    Transport,
    DataConverter
}